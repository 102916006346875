<template>
  <div class="mPoetry">
    <div class="mPoetry-head">
      <div class="head-left">
        <h1>每日古诗文</h1>
        <p>每天5分钟，一年吃透300个古诗文名句名段</p>
        <ul>
          <li>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile.png" />
            <span>北师大、浙大硕博士团队研发</span>
          </li>
          <li>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile.png" />
            <span>分年级课程、原创古诗文动画剧、名师权威讲解</span>
          </li>
          <li>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile.png" />
            <span>经典诵读AI智能打分</span>
          </li>
        </ul>
        <button class="signUp-btn" @click="signUp">立即报名</button>
      </div>
      <img
        src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry-icon.png"
        class="head-img"
      />
    </div>
    <div class="video-contain">
      <!-- <div class="video-box">
        <img
          @click="videoPlay"
          v-if="showVideoBg"
          class="video-bg"
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry-videobg.jpg"
          alt
        />
        <video
          ref="poetryVideo"
          class="video"
          controls
          name="media"
          controlslist="nodownload"
          :class="{hide: showVideoBg}"
        >
          <source src="https://xcx.alicdn2.hexiaoxiang.com/web_static/zh_gsw.mp4" type="video/mp4" />
        </video>
      </div> -->
      <div class="course-brief">
        <h2>
          课程
          <span>介绍</span>
        </h2>
        <p>课程按由浅入深的顺序编排，从传世的经、史、子、集中，精选出300个脍炙人口、含义深刻的千古名句名段，覆盖了诗、词、曲、文言文四大类别；包含了孔子、孟子、李白、杜甫、司马迁等100多位古代大思想家和大文豪的作品；涉及《诗经》、《论语》、《孟子》、《史记》等100多部伟大作品。</p>
      </div>
    </div>
    <div class="brief-list">
      <div class="brief-box">
        <div class="brief-left">
          <h3>学一学</h3>
          <ul>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                小学古诗文总数增加了55篇，
                增幅高达80%
              </span>
            </li>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                初中古诗文总篇数占到了全部
                课文的51.7%
              </span>
            </li>
            <li>
              <span class="point"></span>
              <span class="brief-content">高考必考古诗文增加了5倍</span>
            </li>
          </ul>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry1.png"
          class="brief-img"
        />
      </div>
      <div class="brief-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry2.png"
          class="brief-img"
        />
        <div class="brief-left">
          <h3>课程效果</h3>
          <ul>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                5分钟课程结合Q版动画情
                景剧，注意力集中
              </span>
            </li>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                4大板块科学设计，环环相扣，
                记忆点加深
              </span>
            </li>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                每天6:00解锁，晨读让古诗文
                吸收事半功倍
              </span>
            </li>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                365天日积月累，接受中华传
                统文化熏陶
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="brief-box">
        <div class="brief-left">
          <h3>AI智能诵读打分</h3>
          <ul>
            <li>
              <span class="brief-content">我们从完整度、流利度声韵母、流利度、声调，四个方面检测对孩子的诵读进行评分，让孩子更有目的性的诵读训练。</span>
            </li>
          </ul>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry3.png"
          class="brief-img"
        />
      </div>
    </div>
    <!-- <div class="course-arrange">
            <h2>课程<span>安排</span>（部分）</h2>
            <div class="table-head">
                <div class="time">课时</div>
                <div class="from">选自</div>
                <div class="course">课程</div>
            </div>
            <div class="arrange-table">
                <div class="table-list ">
                    <div class="time">第一讲</div>
                    <div class="from">《史记·陈涉世家》</div>
                    <div class="course">鸿鹄之志</div>
                </div>
                <div class="table-list ">
                    <div class="time">第二讲</div>
                    <div class="from">《史记·淮阴侯列传》</div>
                    <div class="course">多多益善</div>
                </div>
                <div class="table-list ">
                    <div class="time">第三讲</div>
                    <div class="from">《舟过安仁》</div>
                    <div class="course">舟过安仁</div>
                </div>
                 <div class="table-list ">
                    <div class="time">第四讲</div>
                    <div class="from">《史记·廉颇蔺相如列传》</div>
                    <div class="course">完璧归赵</div>
                </div>
                 <div class="table-list ">
                    <div class="time">第五讲</div>
                    <div class="from">《宿建德江》</div>
                    <div class="course">宿建德江</div>
                </div>
                 <div class="table-list ">
                    <div class="time">第六讲</div>
                    <div class="from">《史记·高祖本纪》</div>
                    <div class="course">约法三章</div>
                </div>
                <div class="table-list ">
                    <div class="time">第七讲</div>
                    <div class="from">《淮南子·兵训传》</div>
                    <div class="course">声东击西</div>
                </div>
                
            </div>
            <div class="table-footer">共365讲</div>
    </div>-->
    <!-- 课程安排 -->
    <mCourse :midCourse="midCourse" :primaryCourse="primaryCourse" class="cur-green"></mCourse>
     <button class="more-btn" @click="signUp">报名查看更多课程</button>
     <!-- 教材 -->
     <div class="book">
       <h2>配套教材</h2>
       <div class="book-btn">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-btn1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-btn2.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-btn3.png" >
        </div>
       <div class="book-img">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-book1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poem-book2.png" >
       </div>
     </div>
    <!-- 上课流程 -->
    <div class="process">
      <h2>
        上课
        <span>流程</span>
      </h2>
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/process-notit.png" />
    </div>
    <!-- 我们承诺 -->
    <div class="promise">
      <h2>
        我们
        <span>承诺</span>
      </h2>
      <div class="promise-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/buget.png"
          class="buget"
        />
        <div>
          <h4>无忧退款</h4>
          <p>对课程不满意，对上课老师不满意，对效果不满意，开课15天内可以提出退款要求</p>
        </div>
      </div>
      <div class="promise-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/serve.png"
          class="serve"
        />
        <div>
          <h4>服务承诺</h4>
          <p>根据孩子学习进度实时跟踪监测，专属助教全程提供五星服务，为家长解读各种问题</p>
        </div>
      </div>
      <div class="promise-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/teach.png"
          class="teach"
        />
        <div>
          <h4>师资承诺</h4>
          <p>所有老师均经过层层筛选与培训，擅长语文教育培训</p>
        </div>
      </div>
    </div>
    <!-- 家长反馈 -->
    <mFeedBack :list="feedBackList" colorStyle="#1DBA5C"></mFeedBack>
    <mTool :downloadShow="showDownload"></mTool>
    <receiveCourse :receiveShow="receiveShow" @closeMask="receiveShow=false"></receiveCourse>
  </div>
</template>
<script>
import mFeedBack from "@/components/mFeedBack";
import mTool from "@/components/mTool";
import receiveCourse from "../components/receiveCourse";
import mCourse from "../components/mCourse";
export default {
  data() {
    return {
      showDownload: false,
      receiveShow: false,
      feedBackList: [
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLoibQeYakloErOhFMEFibyW5YpIXNib5JLsoNXsk8GHWt6Sfxpe2u2GG7YV47jqKibAsFd6UXKF1gATg/132",
          name: "木子",
          content: [
            "昨天孩子上了一节，喜欢的不得了！正好新加坡有考口语和听力，孩子这些方面有点不好！平时读得卡卡，声音有小。昨天的课，他反反复复，越读越大声！最后不尽兴，还要多度一段"
          ]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/vcEHqs1t1MViaNibdWASoP20Wd98PFCMf2XOxGP5LF7ibHtdAt2sHyB9TtibIFZBUx9Po3IIqS0VjXRH3c5D0Vfcfg/132",
          name: "小小",
          content: [
            "他今天很高兴的说，老师今天扩展了课本内容，提问千里之行始于足下是什么意思，全班只有她一个人答对的，说每日古诗文的效果"
          ]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/xpAsb3hl87UbMPCkYhgdvOtNUibYLrqtlvW97XhDFCLjJt84LrFcXTvG9ZYI91Vq7Cz2W7fd1k0q1kgJ4H7DibhA/132",
          name: "叶子薇",
          content: [
            "古诗文每天5分钟作为晨读最好啦~真正吃透其意，不在死记硬背，还可以运用到写作中哦"
          ]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJOtpTHichlMq8Mw0P62b1WKkt4I8LpBheLGZHquIkJBwAtf8m1WRgwfUWj7Cicjmb0dn5CWpibj7DQw/132",
          name: "汶汶",
          content: [
            "孩子还是挺喜欢古诗文学习的，每天5分钟。学习完成作业，不停地朗读，为了成为大师。"
          ]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/DYAIOgq83epEaTn4WutFZgTdxkrUwX0FsTP3B0fvEXlRaBHVYHGYJE8qKcichBdFMeY2a3FncVGSAUGJOl1v8WQ/132",
          name: "珊珊",
          content: ["这个评分系统太好了，孩子不服输的一直在读"]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTICYLxHamNQrpMImHB0ScDgnRaNHbvBGnso6eOeaVd2MlD1ibC7Oz2TNjj6CMD6wLg189yhRDLU1hQ/132",
          name: "王诺彦",
          content: [
            "宝贝自从上了古诗文以后，对古诗文兴趣挺大的，有时候还自己写诗"
          ]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/bjN8AiaEicEURpdicpDpcVsvdLWTR3NrIgRqibmhM9fbu3DOQyOe5iaUzkbbY58VTXCv7UApjcnv0Ft1GUk4PxXibctw/132",
          name: "薇笑",
          content: [
            "比我还积极。前天我出差回来晚了，导致他当晚的大语文古诗词没完成，第二天早上醒来第一句就问，早上还能不能补读"
          ]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/ZjGxPhssj80sec51JBx3iaKpcPLEU5ZHlxPXdgTKMPtGLP3oPolWhBnAK0oBjhtIhb0DneGqFeCMN4eQXjtibSHg/132",
          name: "唐诗涵",
          content: [
            "我的孙女她还是喜欢这门课，自己打开去学习，还喊我一起去打卡"
          ]
        },
        {
          avatar:
            "http://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83eqMT1eOahIUbLib6q9UHBsExUj0nuicPWBHGAibfibZpN8F7Whd84oKfqAgBO8picJb6PMy8NCo9AQpDgg/132",
          name: "龚苹",
          content: [
            "你们这种每天5分钟的课程我认为挺好的，太长了孩子有负担就不行了，这种方式对培养孩子的学习习惯有好处。"
          ]
        },
        {
          avatar:
            "https://wx.qlogo.cn/mmopen/vi_32/aRzndKDDP7KsfvEicDaz1mP1xQqwJ2ZWm6uzuYwun5ZFiahze9giansK1pcNsKSx3bkNSvdQZicDLGkRwHTDOL9vaw/132",
          name: "珑月",
          content: [
            "他挺喜欢pk这项，有挑战力，平时一点都不喜欢看书、写字。现在都很主动将积极自己去学。"
          ]
        }
      ],
      primaryCourse: [
        {
          week: ["1"],
          classic: "多多益善",
          knowledge: ["臣多多益善耳"],
          power: ["《史记·淮阴侯列传》"]
        },
        {
          week: ["2"],
          classic: "一鸣惊人",
          knowledge: ["   不鸣则已，一鸣惊人。"],
          power: ["  《史记·滑稽列传》"]
        },
        {
          week: ["3"],
          classic: "《迟日江山丽》 ",
          knowledge: [" 迟日江山丽，春风花草香。泥融飞燕子，沙暖睡鸳鸯。"],
          power: ["  《绝句二首》（其一）"]
        },
        {
          week: ["4"],
          classic: "千里之行",
          knowledge: [" 千里之行，始于足下"],
          power: [" 《老子》"]
        },
        {
          week: ["5"],
          classic: "当断则断",
          knowledge: [" 当断不断，反受其乱。"],
          power: ["《史记·齐悼惠王世家》"]
        },
        {
          week: ["6"],
          classic: "幼女词",
          knowledge: ["幼女才六岁，未知巧与拙。向夜在堂前，学人拜新月。"],
          power: ["《幼女词》"]
        },
        {
          week: ["7"],
          classic: "登黄鹤楼",
          knowledge: ["叹江山如故，千村寥落！"],
          power: ["  《满江红·登黄鹤楼有感》  "]
        },
        {
          week: ["8"],
          classic: "知错就改",
          knowledge: ["古之君子，过则改之。"],
          power: ["《孟子·公孙丑章句下》"]
        },
        {
          week: ["9"],
          classic: "出类拔萃",
          knowledge: ["出于其类，拔乎其萃。"],
          power: ["《孟子·公孙丑章句上》"]
        },
        {
          week: ["10"],
          classic: "题画诗",
          knowledge: [
            " 竹外桃花三两枝，春江水暖鸭先知。蒌蒿满地芦芽短，正是河豚欲上时。"
          ],
          power: ["《惠崇春江晚景》"]
        },
        {
          week: ["11"],
          classic: "人生在勤",
          knowledge: ["人生在勤，不索何获"],
          power: ["《后汉书·张衡列传》"]
        },
        {
          week: ["12"],
          classic: "鸟尽弓藏",
          knowledge: ["狡兔死，走狗烹；飞鸟尽，良弓藏。"],
          power: ["《资治通鉴·汉纪三》"]
        },
        {
          week: ["13"],
          classic: "绳锯木断",
          knowledge: ["绳锯木断，水滴石穿。"],
          power: ["《鹤林玉露》"]
        },
        {
          week: ["14"],
          classic: "若要人不知",
          knowledge: ["若要人不知，除非己莫为。"],
          power: ["《增广贤文》"]
        },
        {
          week: ["15"],
          classic: "巧妙回绝",
          knowledge: ["山中何所有，岭上多白云。只可自怡悦，不堪持赠君。"],
          power: ["《诏问山中何所有赋诗以答》"]
        },
        {
          week: ["16"],
          classic: "后来居上",
          knowledge: ["后来者居上。"],
          power: ["《史记·汲郑列传》"]
        },
        {
          week: ["17"],
          classic: "礼尚往来",
          knowledge: ["礼尚往来。往而不来，非礼也。"],
          power: ["《礼记·曲礼上》"]
        },
        {
          week: ["18"],
          classic: "人一能之",
          knowledge: ["人一能之，己百之。人十能之，己千之。"],
          power: ["《中庸》"]
        }
      ],
      midCourse: [
        {
          week: ["1"],
          classic: " 鸿鹄之志",
          knowledge: ["燕雀安知鸿鹄之志。"],
          power: ["《史记·陈涉世家》"]
        },
        {
          week: ["2"],
          classic: "完璧归赵",
          knowledge: ["城不入，臣请完璧归赵。"],
          power: ["《史记·廉颇蔺相如列传》"]
        },
        {
          week: ["3"],
          classic: "舟过安仁",
          knowledge: [
            "一叶渔船两小童，收篙停棹坐船中。怪生无雨都张伞，不是遮头是使风。"
          ],
          power: ["《舟过安仁》"]
        },
        {
          week: ["4"],
          classic: "约法三章",
          knowledge: ["与父老约法三章耳；杀人者死，伤人及盗抵罪。"],
          power: ["《史记·高祖本纪》"]
        },
        {
          week: ["5"],
          classic: "声东击西",
          knowledge: ["将欲西而示之以东。"],
          power: ["《淮南子·兵略训》"]
        },
        {
          week: ["6"],
          classic: "宿建德江",
          knowledge: ["移舟泊烟渚，日暮客愁新。野旷天低树，江清月近人"],
          power: ["《宿建德江》"]
        },
        {
          week: ["7"],
          classic: "取而代之",
          knowledge: ["彼可取而代也。"],
          power: ["《史记·项羽本纪》"]
        },
        {
          week: ["8"],
          classic: "四面楚歌",
          knowledge: ["夜闻汉军四面皆楚歌，项王乃大惊"],
          power: ["《史记·项羽本纪》"]
        },
        {
          week: ["9"],
          classic: "温故知新",
          knowledge: ["学而时习之，不亦说乎？"],
          power: ["《论语·学而》"]
        },
        {
          week: ["10"],
          classic: "渡浙江问舟中人",
          knowledge: [
            "潮落江平未有风，扁舟共济与君同。时时引领望天末，何处青山是越中。"
          ],
          power: ["《渡浙江问舟中人》"]
        },
        {
          week: ["11"],
          classic: "项庄舞剑",
          knowledge: ["今者项庄拔剑舞，其意常在沛公也。"],
          power: ["《史记·项羽本纪》"]
        },
        {
          week: ["12"],
          classic: "前事不忘",
          knowledge: ["今者项庄拔剑舞，其意常在沛公也。"],
          power: ["《史记·秦始皇本纪》"]
        },
        {
          week: ["13"],
          classic: "咏柳",
          knowledge: [
            "碧玉妆成一树高，万条垂下绿丝绦。不知细叶谁裁出，二月春风似剪刀。"
          ],
          power: ["《咏柳》"]
        },
        {
          week: ["14"],
          classic: "三人成虎",
          knowledge: ["夫市之无虎明矣，然而三人言而成虎。"],
          power: ["《战国策·魏策二》"]
        },
        {
          week: ["15"],
          classic: "仓廪实",
          knowledge: ["仓廪实而知礼节，衣食足而知荣辱。"],
          power: ["《史记·管晏列传》"]
        },
        {
          week: ["16"],
          classic: "山居秋暝",
          knowledge: ["竹喧归浣女，莲动下渔舟。随意春芳歇，王孙自可留。"],
          power: ["《山居秋暝》（节选）"]
        },
        {
          week: ["17"],
          classic: "人而无信",
          knowledge: ["人而无信，不知其可也。"],
          power: ["《论语·为政》"]
        },
        {
          week: ["18"],
          classic: "父母之年",
          knowledge: ["父母之年，不可不知也。一则以喜，一则以惧。"],
          power: ["《论语·里仁》"]
        }
      ],
      showVideoBg: true
    };
  },
  components: {
    mFeedBack,
    mTool,
    receiveCourse,
    mCourse
  },
  methods: {
    signUp() {
      // window.location.href = "https://yw.hexiaoxiang.com/goods/purchase.html#/?productId=10";
      this.receiveShow = true;
    },
    // 播放视频
    videoPlay() {
      this.showVideoBg = false;
      let video = this.$refs.poetryVideo;
      let that = this;
      video
        .play()
        .then(res => {
          video.addEventListener("pause", () => {
            that.showVideoBg = true;
          });
          video.addEventListener('seeking',()=>{
            that.showVideoBg=false
          })
        })
        .catch(err => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.mPoetry {
  .mPoetry-head {
    background: url(https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/poetry-banner.png)
      no-repeat;
    background-size: 100% 100%;
    padding: 0.853rem 0.48rem 0;
    position: relative;
    height: 7.893rem;
    .head-left {
      color: #fff;
      text-align: left;
      h1 {
        font-size: 0.72rem;
        font-weight: bold;
      }
      p {
        font-size: 0.373rem;
        margin: 0.227rem 0 0.7rem;
      }
      ul {
        font-size: 0.32rem;
        li {
          line-height: 0.72rem;
          img {
            height: 0.093rem;
          }
        }
      }
      button {
        width: 3.2rem;
        height: 0.8rem;
        border-radius: 0.4rem;
        background: linear-gradient(
          270deg,
          rgba(248, 120, 51, 1) 0%,
          rgba(255, 139, 53, 1) 100%
        );
        color: #fff;
        font-size: 0.373rem;
        font-weight: bold;
        margin-top: 0.533rem;
      }
    }
    .head-img {
      width: 5.067rem;
      height: 3.587rem;
      position: absolute;
      top: 3.773rem;
      right: 0.16rem;
    }
  }
  .video-contain {
    display: flex;
    padding: .8rem 0.32rem;
    align-items: center;
    .video-box {
      // position: relative;
      // padding-bottom: .453rem;
      .video-bg {
        height: 4.693rem;
        display: block;
      }
      .video {
        height: 4.693rem;
      }
      .hide {
        display: none;
      }
    }
  }
  .course-brief {
    margin-left: 0.267rem;
    h2 {
      font-size: 0.6rem;
      color: #323232;
      font-weight: bold;
      text-align: left;
    }
    p {
      font-size: 0.373rem;
      color: #323232;
      line-height: 0.533rem;
      text-align: left;
      margin-top: 0.267rem;
    }
  }

  .brief-list {
    background-color: #f6f6f9;
    padding-bottom: 0.267rem;
    .brief-box {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      height: 4.907rem;
      &:not(:last-child) {
        margin-bottom: 0.267rem;
      }
      &:nth-child(2){
        padding: 1rem 0;
      }
      &:first-child .brief-left {
        width: 4.427rem;
        margin-right: 0.787rem;
      }
      &:nth-child(2) .brief-left {
        width: 4.36rem;
        margin-left: 0.853rem;
        h3 {
          margin-bottom: 0.1rem;
        }
      }
      &:nth-child(3) .brief-left {
        width: 4.64rem;
        margin-right: 0.773rem;
      }
      .brief-left {
        text-align: left;
        h3 {
          color: #444144;
          font-size: 0.6rem;
          font-weight: bold;
          margin-bottom: 0.347rem;
        }
        ul {
          font-size: 0.373rem;
          li {
            display: flex;
            align-items: center;
            line-height: 0.533rem;
            .point {
              background-color: #3bd97b;
              width: 0.08rem;
              height: 0.08rem;
              display: block;
            }
            .pointTwo {
              margin-top: -0.6rem;
            }
            .brief-content {
              color: #706c70;
              margin-left: 0.133rem;
            }
          }
        }
      }
      .brief-img {
        width: 3.227rem;
      }
    }
  }
  .course-arrange {
    h2 {
      color: #444144;
      font-size: 0.587rem;
      margin: 0.573rem 0.48rem;
      span {
        color: #1dba5c;
      }
    }
    .table-head {
      display: flex;
      justify-content: center;
      margin-bottom: 0.053rem;
      div {
        background-color: #1dba5c;
        color: #fff;
        font-weight: bold;
        font-size: 0.4rem;
        height: 0.747rem;
        line-height: 0.747rem;
      }
      .time {
        width: 2.453rem;
        border-radius: 0.16rem 0 0 0;
      }
      .from {
        width: 4rem;
        margin: 0 0.067rem;
      }
      .course {
        width: 2.453rem;
        border-radius: 0 0.16rem 0 0;
      }
    }
    .arrange-table {
      display: flex;
      align-items: center;
      flex-direction: column;
      .table-list {
        display: flex;
        color: #444144;
        font-size: 0.32rem;
        margin-bottom: 0.053rem;
        div {
          height: 0.747rem;
          line-height: 0.747rem;
          background-color: #86e3ac;
        }
        &:nth-child(2n + 1) div {
          background-color: #ebfff3;
        }
        .time {
          width: 2.453rem;
        }
        .course {
          width: 2.453rem;
        }
        .from {
          width: 4rem;
          margin: 0 0.053rem;
        }
      }
    }
    .table-footer {
      background-color: #1dba5c;
      font-size: 0.4rem;
      color: #fff;
      font-weight: bold;
      width: 9.04rem;
      height: 0.747rem;
      border-radius: 0 0 0.16rem 0.16rem;
      line-height: 0.747rem;
      margin: 0 auto;
    }
  }
  .book{
     padding-bottom: 0.667rem;
     background: #1EBA5C;
     padding-top: .64rem;
     h2{
      font-size: 0.587rem;
      color: #fff;
      font-weight: bold;
      margin-bottom: .213rem;
     }
     .book-btn{
       display: flex;
       justify-content: center;
       img{
         height: .667rem;
         &:not(:last-child){
           margin-right: .267rem;
         }
       }
     }
     .book-img{
       img{
         height: 8.373rem;
         margin-top: .64rem;
       }
     }
  }
  .process {
    padding-bottom: 0.667rem;
    h2 {
      font-size: 0.587rem;
      color: #444144;
      font-weight: bold;
      margin: 1.373rem 0 0.6rem;
      span {
        color: #1dba5c;
      }
    }
    img {
      height: 6.307rem;
    }
  }
  .promise {
    background-color: #f6f6f9;
    padding: 0.627rem 0 0.667rem;
    h2 {
      font-size: 0.587rem;
      color: #444144;
      font-weight: bold;
      margin-bottom: 0.533rem;
      span {
        color: #1dba5c;
      }
    }
    .promise-box {
      width: 9.04rem;
      height: 2.133rem;
      border-radius: 0.213rem;
      box-shadow: 0px 32px 50px 0px rgba(161, 161, 172, 0.18);
      background: #fff;
      margin: 0 auto;
      display: flex;
      margin-bottom: 0.4rem;
      align-items: center;
      img {
        margin: 0 0.253rem 0 0.427rem;
      }
      .buget {
        height: 0.867rem;
      }
      .teach {
        height: 1.067rem;
      }
      .serve {
        height: 1.027rem;
      }
      div {
        text-align: left;
        width: 6.64rem;
        padding-left: 0.253rem;
        border-left: 1px dashed #eeeeee;

        h4 {
          color: #444144;
          font-size: 0.427rem;
          font-weight: bold;
          margin-bottom: 0.213rem;
        }
        p {
          font-size: 0.32rem;
          color: #888888;
        }
      }
    }
  }
  .m-feedback {
    padding: 0.667rem 0 0.933rem;
  }
  .more-btn{
    width: 4.2rem;
    height: 0.8rem;
    border-radius: 0.4rem;
    background: -webkit-gradient(linear, right top, left top, from(#f87833), to(#ff8b35));
    background: linear-gradient(270deg, #f87833 0%, #ff8b35 100%);
    color: #fff;
    font-size: 0.373rem;
    font-weight: bold;
    margin:0 auto 1.067rem;
  }
}
</style>
